<script>
import footerLayout from "../../layouts/footerLayout";
import headerLayout from "../../layouts/headerLayout";

import deliveryAddressPopup from "../popUpModals/deliveryAddressPopup";

import Vue from 'vue';

import { AlertPlugin } from 'bootstrap-vue'
Vue.use(AlertPlugin)

import { TabsPlugin } from 'bootstrap-vue'
Vue.use(TabsPlugin)

import { CardPlugin } from 'bootstrap-vue'
Vue.use(CardPlugin)

import { DropdownPlugin } from 'bootstrap-vue'
Vue.use(DropdownPlugin)

import { VuePicker, VuePickerOption } from '@invisiburu/vue-picker'

import '@invisiburu/vue-picker/dist/vue-picker.min.css'


Vue.component('VuePicker', VuePicker)
Vue.component('VuePickerOption', VuePickerOption)


export default {
    data() {
      return {
          userName:'',
          emailID:'',
          mobileNo:'',
          isCorporateUser:'',
        //   corporateDetails:'',
        corporateName:'',
          userAddress:[],
          userReview: [],
          ordersDetails:[],
          vouchersDetails:[],
          allVouchersDetails: [],
          userAddressData:'',
          OrdersShimmer:0,
          walletBalance:0,
          transactionType:0,
          transactionsToShow:50,
          reviewsToShow: 5,
          walletTableData:[],
        //   orderStatus:''
      }
    },

    components: {
				 footerLayout,
                 headerLayout,
                 deliveryAddressPopup
                },
    mounted() {
        if(this.$route.params.profileType){
                this.profileType = this.$route.params.profileType;
            }
        // console.log(this.$userData);
    //    if (this.$userData !== null) {
	// 		this.userID = this.$userData.userID;
	// 		this.userName = this.$userData.userName;
	// 		this.emailID = this.$userData.emailID;
	// 		this.mobileNo = this.$userData.mobileNo;
	// 		this.isCorporateUser = this.$userData.isCorporateUser;
	// 		// this.corporateName = this.$userData.corporateName;
	// 		this.corporateCode = this.$userData.corporateCode;
	// 	}
        // if(this.isCorporateUser == 1)
        // {
        //     this.getCorporateDetails();
        // }
        this.getUserProfile();
        this.getUserAddressProfile();
        this.getOrdersByUserID();
        this.getUserWalletDetails();
        this.getUserVouchersID();
        this.getUserReviews(this.$userData.userID);
    },
    methods: {
        pushPofile(profileTypeTab)
        {
            this.$router.push('/profile/'+profileTypeTab);

            if (profileTypeTab == 'my-orders') {
                this.$analytics.logEvent('profile_orders', { 
                    userID: localStorage.getItem('userID'), 
                    userName: localStorage.getItem('userName'), 
                    profileTypeTab: profileTypeTab,
                }, { 'debug_mode':true })
            } else if (profileTypeTab == 'my-vouchers') {
                this.$analytics.logEvent('profile_vouchers', { 
                    userID: localStorage.getItem('userID'), 
                    userName: localStorage.getItem('userName'), 
                    profileTypeTab: profileTypeTab,
                }, { 'debug_mode':true })
            } else if (profileTypeTab == 'wallet') {
                this.$analytics.logEvent('profile_wallet', { 
                    userID: localStorage.getItem('userID'), 
                    userName: localStorage.getItem('userName'), 
                    profileTypeTab: profileTypeTab,
                }, { 'debug_mode':true })
            } else if (profileTypeTab == 'my-address') {
                this.$analytics.logEvent('profile_address', { 
                    userID: localStorage.getItem('userID'), 
                    userName: localStorage.getItem('userName'), 
                    profileTypeTab: profileTypeTab,
                }, { 'debug_mode':true })
            } else if (profileTypeTab == 'my-review') {
                this.$analytics.logEvent('profile_review', { 
                    userID: localStorage.getItem('userID'), 
                    userName: localStorage.getItem('userName'), 
                    profileTypeTab: profileTypeTab,
                }, { 'debug_mode':true })
            } else if (profileTypeTab == 'my-profile') {
                this.$analytics.logEvent('profile_profile', { 
                    userID: localStorage.getItem('userID'), 
                    userName: localStorage.getItem('userName'), 
                    profileTypeTab: profileTypeTab,
                }, { 'debug_mode':true })
            }
        },
        redirectToRestaurant(restIdentifierString)
        {
            this.$router.push('/restaurantDetails/'+restIdentifierString);
        },
    //    getCorporateDetails()
    //    {        
            
    //         let getCorpData = JSON.stringify({ userID: this.userID,
    //                                         });

    //         this.axios.post('/getCorporateDetails', getCorpData)
    //         .then((res)=>{
    //             // console.log(res);
    //             let status = res.status;
    //             if(status == 200 && res.data.status==200)
    //             {
    //                 this.corporateDetails = res.data.data;
    //             }else{
    //                 console.log(res.data.message);
    //             }
    //         });
    //     },
        getUserProfile()
        {
            this.axios.get('/getUserProfile')
            .then((res)=>{
                // console.log(res);
                let status = res.status;
                if(status == 200 && res.data.status==200)
                {
                    this.userName = res.data.userData.userName;
            		this.emailID = res.data.userData.emailID;
            		this.mobileNo = res.data.userData.mobileNo;
            		this.isCorporateUser = res.data.userData.isCorporateUser;
            		this.corporateName = res.data.userData.corporateName;
            		this.corporateCode = res.data.userData.corporateCode;
                }else{
                    console.log(res.data.message);
                }
            });
        },
        getUserAddressProfile()
        {
            // let getAddressesData = JSON.stringify({ userID: this.userID,
            //                                 });

            // this.axios.post('/getUserAddressProfile', getAddressesData)
            this.axios.get('/getUserAddressProfile')
            .then((res)=>{
                // console.log(res);
                let status = res.status;
                if(status == 200 && res.data.status==200)
                {
                    this.userAddress = res.data.data;
                }else{
                    console.log(res.data.message);
                }
            });
        },
        getUserReviews() {
          this.axios.get("/getUserReviews").then((res) => {
            let status = res.status;
            if (status == 200 && res.data.status == 200) {
              this.userReview = res.data.data;
              } else {
                console.log(res.data.message);
              }
            });
        },
        getUserWalletDetails()
        {
            this.axios.get('/getUserWalletDetails', {
												params: {
													transactionType: this.transactionType,
												}
            
        })
            .then((res)=>{
                // console.log(res);
                let status = res.status;
                if(status == 200 && res.data.status==200)
                {
                    this.walletBalance = res.data.walletBalance;
                    this.walletTableData = res.data.walletTransactions;
                }else{
                    console.log(res.data.message);
                }
            });
        },
        getOrdersByUserID()
        {
            // let getOrdersData = JSON.stringify({ userID: this.userID,
            //                                 });

            // this.axios.post('/getOrdersByUserID', getOrdersData)
            this.axios.get('/getOrdersByUserID')
            .then((res)=>{
                this.OrdersShimmer = 1;
                // console.log(res);
                let status = res.status;
                if(status == 200 && res.data.status==200)
                {
                    this.ordersDetails = res.data.data;
                }else{
                    console.log(res.data.message);
                }
            });
        },

        orderDetailsEvent(orderID)
        {
            this.$analytics.logEvent('order_details', { 
                userID: localStorage.getItem('userID'), 
                userName: localStorage.getItem('userName'), 
                orderID: orderID,
            }, { 'debug_mode':true })
        },

        // getVouchersByUserID()
        getUserVouchersID()
        {
            this.axios.get('/getUserVouchersID')
            .then((res)=>{
                // console.log(res);
                let status = res.status;
                if(status == 200 && res.data.status==200)
                {
                    this.vouchersDetails = res.data.data;
                }else{
                    console.log(res.data.message);
                }
            });
        },

        getUserVouchersByVoucherID(userVoucherArr)
        {
            this.allVouchersDetails = userVoucherArr;
            this.$bvModal.show("modal-allVoucher");
        },

        // getUserVouchersByVoucherID(userVoucherID)
        // {
        //     let userVoucherDetails = JSON.stringify({ 
		// 		userVoucherID: userVoucherID
		// 		});
        //     this.axios.post('/getUserVouchersByVoucherID', userVoucherDetails)
        //     .then((res)=>{
        //         // console.log(res);
        //         let status = res.status;
        //         if(status == 200 && res.data.status==200)
        //         {
        //             this.allVouchersDetails = res.data.data;
        //         }else{
        //             console.log(res.data.message);
        //         }
        //     });
        // },


        addressModal()
        {
            this.userAddressData = "";
            this.$bvModal.show("modal-addAddress");
        },
        editAdress(userAddID)
        {
            // alert(userAddID);
            let getUserAddressData = JSON.stringify({ 
                // userID: this.userID,
                                                      userAddressID: userAddID
                                            });

            this.axios.post('/getUserAddressByAddID', getUserAddressData)
            .then((res)=>{
                // console.log(res);
                let status = res.status;
                if(status == 200 && res.data.status==200)
                {
                    // this.editAddressByID = deliveryAddressPopup.methods.idForEditAddress;
                    // this.editAddressByID(res.data.data);
                    this.userAddressData = res.data.data;
                    this.$bvModal.show("modal-addAddress");
                }else{
                    console.log(res.data.message);
                }
            });
            // this.editAddressByID = deliveryAddressPopup.methods.idForEditAddress;
            // this.editAddressByID(userAddID);
            // this.$bvModal.show("modal-addAddress");
        },
        removeAddress(userAddID)
        {
            let getUserAddressData = JSON.stringify({ 
                // userID: this.userID,
                                                      userAddressID: userAddID
                                            });

            this.axios.post('/removeUserAddress', getUserAddressData)
            .then((res)=>{
                // console.log(res);
                let status = res.status;
                if(status == 200 && res.data.status==200)
                {
                    alert('Address deleted Successfully');
                    location.reload();
                    // this.editAddressByID = deliveryAddressPopup.methods.idForEditAddress;
                    // this.editAddressByID(res.data.data);
                    // this.$bvModal.show("modal-addAddress");
                }else{
                    console.log(res.data.message);
                }
            });
        }
    }
}
</script>
<template>
<div>
        <headerLayout />
		<deliveryAddressPopup :userAddressData="this.userAddressData"/>
    <main>
        <div class="mainDiv">
        <b-tabs
              pills
              vertical
              nav-class="p-0"
              nav-wrapper-class="col-sm-2 tabsPostion noMobile"
              content-class="pt-0 px-2 text-muted"
            >
              <b-tab title="My Orders" @click="pushPofile('my-orders')" :active="profileType == 'my-orders'" title-item-class="mb-2 pills-li">
                <b-card-text>
                    <div class="section-services container">
                        <div class="reviews">
                            <div class="">

                                 <div class="row">
					                <div class="col-md-6 review_content">
					                    <div class="clearfix">
                                            <h3 class="cardTitle" v-if="OrdersShimmer == 0"><VShimmerLine width="50" /></h3>
					                        <h3 class="cardTitle" v-else>My Orders</h3>
					                    </div>
					                </div>
                                    
					            </div>
					            <!-- /row -->

                                <!-- <div class="row reply"  v-if="ordersDetails.length > 0">
					                <div class="col-md-6 otherAdd" v-for="orderDetailsobj in ordersDetails" v-bind:key="orderDetailsobj">
					                    <div class="deliveryCard align-left">
                                            <div class="orderTotalSum">
                                                <h5>Order ID: {{orderDetailsobj.orderID}}</h5>
                                            </div>

                                            <div class="totalOrderSum">
                                                <p class="">₹{{orderDetailsobj.orderTotalSum}}</p>
                                            </div>
                                            <div class="address">
                                                <span class="placedOn">Placed on: {{orderDetailsobj.placedOn}}</span>
                                            </div>
                                            <div class="row orderStatus_ViewOrder">
                                                <p class="col-6 orderStatus" :style="{color : orderDetailsobj.statusColor}">{{orderDetailsobj.orderStatus}}</p>
					                            
                                                <div class="col-6 align-right">
                                                    <a :href="'/orderDetails?id='+orderDetailsobj.statusColor+'?od=1'">
					                                    <button class="btn_1">View Details</button>
                                                    </a>
                                                </div>
                                            </div>

                                        </div>
					                </div>
					            </div> -->
                                <section v-if="OrdersShimmer == 0">
                                    <div class="row">
                                        <div class="col-md-6" v-for="n in 10" v-bind:key="n">
                                        <div class="strip">
                                            <a class="menu_item modal_dialog">
                                                <VShimmerLine width="16" heightPx="24" style="position: absolute;bottom:43px;right:20px;" />
                                                <h3><VShimmerLine width="40" /></h3>
                                                <VShimmerLine width="20" />
                                                <p style="margin-top:15px;" class="two-line-text"><VShimmerLine width="50" /></p>
                                                <br>
                                            <VShimmerLine width="8" />
                                            </a>
                                        </div>
                                        </div>
                                        
                                    </div>
                                    <!-- /row -->
                                </section>
                                <!-- /section -->
                                <section class="ordersSection"  v-if="ordersDetails.length > 0 && OrdersShimmer == 1">
                                    <div class="row">
                                        <div  class="col-md-6" v-for="orderDetailsobj in ordersDetails" v-bind:key="orderDetailsobj">
                                            <div class="strip">
                                                <a class="menu_item">
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <h3>Order ID: {{ orderDetailsobj.orderID }}</h3>
                                                        </div>
                                                        <div class="col-6">
                                                            <span
                                                                class="rating score CateresScore"
                                                                style="float: right;"
                                                                v-if="orderDetailsobj.rating != ''"
                                                            >
                                                                <strong>{{ orderDetailsobj.rating }}</strong>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <p class="orderTotalSum">₹{{orderDetailsobj.orderTotalSum}}</p>
                                                    <span class="placedOn">Placed on: {{orderDetailsobj.placedOn}}</span>
                                                    <div class="row orderStatus_ViewOrder">
                                                        <p class="col-6 orderStatus" :style="{color : orderDetailsobj.statusColor}">{{orderDetailsobj.orderStatus}}</p>
                                                        
                                                        <div class="col-6 align-right">
                                                            <a :href="'/orderDetails/viewDetails?id='+orderDetailsobj.orderID+'?od=1'" @click="orderDetailsEvent(orderDetailsobj.orderID)">
                                                                <button class="btn_1 view_details">View Details</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                            </div>
                        </div>
                    </div>
                </b-card-text>
              </b-tab>
              <!-- tab end -->

              <!-- <b-tab title="My Subscriptions" @click="pushPofile('my-subscriptions')" :active="profileType == 'my-subscriptions'" title-item-class="mb-2 pills-li">
                <b-card-text>
                     <div class="section-services container">
                        <div class="comingSoonCard">
                            <h1 class="comingSoonText align-center">Coming Soon</h1>
                        </div>
                    </div>
                </b-card-text>
              </b-tab> -->
              <!-- tab end -->

              <!-- <b-tab title="Pre-Buy Vouchers" @click="pushPofile('my-vouchers')" :active="profileType == 'my-vouchers'" title-item-class="mb-2 pills-li">
                <b-card-text>
                    <div class="section-services container">
                        <div class="reviews">
                            <div class="">

                                 <div class="row">
					                <div class="col-md-6 review_content">
					                    <div class="clearfix">
					                        <h3 class="cardTitle">Pre-Buy Vouchers</h3>
					                    </div>
					                </div>
                                    
					            </div> -->
					            <!-- /row -->
                                <!-- <section class="ordersSection"  v-if="vouchersDetails.length > 0">
                                    <div class="row">
                                        <div class="preBuyCard col-6" v-on:click="redirectToRestaurant(vouchersDetailsobj.restIdentifierString)" v-for="vouchersDetailsobj in vouchersDetails" v-bind:key="vouchersDetailsobj">
                                            <div class="preBuyTemplate templateType1" v-if="vouchersDetailsobj.templateType == 1">
                                                <div class="preBuyImage" :style="{background : vouchersDetailsobj.templateColor}">
                                                    <div class="Voucheropacity-mask">
                                                        <div class="voucherDetails">
                                                            <span class="voucherStatus" :style="{color : vouchersDetailsobj.voucherStatusColor}">{{vouchersDetailsobj.voucherStatus}}</span>
                                                            <img class="preBuyImageTempalet1" :src="vouchersDetailsobj.templateImage == null || vouchersDetailsobj.templateImage == ''? 'img' : vouchersDetailsobj.templateImage">
                                                            <h5 class="preBuyCouponTitle">#{{vouchersDetailsobj.voucherIdentifier}}</h5>
                                                            <p class="PreCouponDesc one-line-text">{{vouchersDetailsobj.restaurantName}}</p>
                                                            <p class="PreCouponDesc one-line-text">{{vouchersDetailsobj.dishTitle != '' ? vouchersDetailsobj.dishTitle+ ' |' : ''}} ₹{{vouchersDetailsobj.voucherAmt}} </p>
                                                            <p class="PreCouponDesc one-line-text"> Expire on: {{vouchersDetailsobj.expiryDate}}</p>
                                                            <a class="viewRestBtnAnch" :href="'/restaurantDetails/'+vouchersDetailsobj.restIdentifierString">
                                                                <button class="btn_1 viewRestBtn view_details">View Restaurant</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <!-- /template 1 -->

                                            <!-- <div class="preBuyTemplate templateType2" v-if="vouchersDetailsobj.templateType == 2">
                                                <div class="preBuyImage" :style="{ backgroundImage: 'url(' + vouchersDetailsobj.templateImage + ')' }">
                                                    <div class="Voucheropacity-mask">
                                                        <div class="voucherDetails">
                                                            <span class="voucherStatus" :style="{color : vouchersDetailsobj.voucherStatusColor}">{{vouchersDetailsobj.voucherStatus}}</span>
                                                            <h5 class="preBuyCouponTitle">#{{vouchersDetailsobj.voucherIdentifier}}</h5>
                                                            <p class="PreCouponDesc one-line-text">{{vouchersDetailsobj.restaurantName}}</p>
                                                            <p class="PreCouponDesc one-line-text">{{vouchersDetailsobj.dishTitle != '' ? vouchersDetailsobj.dishTitle+ ' |' : ''}} ₹{{vouchersDetailsobj.voucherAmt}} </p>
                                                            <p class="PreCouponDesc one-line-text"> Expire on: {{vouchersDetailsobj.expiryDate}}</p>
                                                            <a class="viewRestBtnAnch" :href="'/restaurantDetails/'+vouchersDetailsobj.restIdentifierString">
                                                                <button class="btn_1 viewRestBtn view_details">View Restaurant</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->

                                            <!-- /template 2 -->

                                            <!-- <div class="preBuyTemplate templateType3" v-if="vouchersDetailsobj.templateType == 3">
                                                <div class="preBuyImage" :style="{ backgroundImage: 'url(' + vouchersDetailsobj.templateImage + ')' }">
                                                    <div class="Voucheropacity-mask">
                                                        <div class="voucherDetails">
                                                            <span class="voucherStatus" :style="{color : vouchersDetailsobj.voucherStatusColor}">{{vouchersDetailsobj.voucherStatus}}</span>
                                                            <h5 class="preBuyCouponTitle">#{{vouchersDetailsobj.voucherIdentifier}}</h5>
                                                            <p class="PreCouponDesc one-line-text">{{vouchersDetailsobj.restaurantName}}</p>
                                                            <p class="PreCouponDesc one-line-text">{{vouchersDetailsobj.dishTitle != '' ? vouchersDetailsobj.dishTitle+ ' |' : ''}} ₹{{vouchersDetailsobj.voucherAmt}} </p>
                                                            <p class="PreCouponDesc one-line-text"> Expire on: {{vouchersDetailsobj.expiryDate}}</p>
                                                            <a class="viewRestBtnAnch" :href="'/restaurantDetails/'+vouchersDetailsobj.restIdentifierString">
                                                                <button class="btn_1 viewRestBtn view_details">View Restaurant</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <!-- /template 3 -->
                                        <!-- </div>
                                    </div> -->
                                    <!-- <div class="row">
                                        <div  class="col-md-5" v-for="vouchersDetailsobj in vouchersDetails" v-bind:key="vouchersDetailsobj">
                                            <div class="strip voucherStrip">
                                                <a class="menu_item">
                                                    <h3>#{{vouchersDetailsobj.voucherIdentifier}}</h3>
                                                    <p class="orderTotalSum">{{vouchersDetailsobj.restaurantName}}</p>
                                                    <p class="placedOn"><span v-if="vouchersDetailsobj.dishTitle != ''">{{vouchersDetailsobj.dishTitle}} | </span> ₹{{vouchersDetailsobj.voucherAmt}} | Expire on: {{vouchersDetailsobj.expiryDate}}</p>
                                                    <span class="placedOn">Voucher Type : {{vouchersDetailsobj.voucherType == 2 ? 'Product' : 'Cash'}}</span>
                                                    <div class="row orderStatus_ViewOrder">
                                                        <p class="col-6 orderStatus" :style="{color : vouchersDetailsobj.voucherStatusColor}">{{vouchersDetailsobj.voucherStatus}}</p>
                                                        
                                                        <div class="col-6 align-right">
                                                            <a :href="'/restaurantDetails/'+vouchersDetailsobj.restIdentifierString">
                                                                <button class="btn_1 view_details">View Restaurant</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div> -->
                                <!-- </section>

                            </div>
                        </div>
                    </div>
                </b-card-text>
              </b-tab> -->
              <!-- tab end -->

              <b-tab title="Pre-Buy Vouchers" @click="pushPofile('my-vouchers')" :active="profileType == 'my-vouchers'" title-item-class="mb-2 pills-li">
                <b-card-text>
                    <div class="section-services container">
                        <div class="reviews">
                            <div class="">

                                 <div class="row">
					                <div class="col-md-6 review_content">
					                    <div class="clearfix">
					                        <h3 class="cardTitle">Pre-Buy Vouchers</h3>
					                    </div>
					                </div>
                                    
					            </div>
					            <!-- /row -->
                                <section class="ordersSection"  v-if="vouchersDetails.length > 0">
                                    <div class="row">
                                        <div class="preBuyCard col-6" v-on:click="getUserVouchersByVoucherID(vouchersDetailsobj.allUserVouchers)" v-for="vouchersDetailsobj in vouchersDetails" v-bind:key="vouchersDetailsobj">
                                            <div class="preBuyTemplate templateType1" v-if="vouchersDetailsobj.templateType == 1">
                                                <div class="preBuyImage" :style="{background : vouchersDetailsobj.templateColor}">
                                                    <div class="Voucheropacity-mask">
                                                        <div class="voucherDetails">
                                                            <!-- <span class="voucherStatus" :style="{color : vouchersDetailsobj.voucherStatusColor}">{{vouchersDetailsobj.voucherStatus}}</span> -->
                                                            <img class="preBuyImageTempalet1" :src="vouchersDetailsobj.templateImage == null || vouchersDetailsobj.templateImage == ''? 'img' : vouchersDetailsobj.templateImage">
                                                            <h5 class="preBuyCouponTitle">{{vouchersDetailsobj.voucherTitle}}</h5>
                                                            <p class="PreCouponDesc two-line-text">{{vouchersDetailsobj.shortDesc}}</p>
                                                            <!-- <p class="PreCouponDesc one-line-text">{{vouchersDetailsobj.dishTitle != '' ? vouchersDetailsobj.dishTitle+ ' |' : ''}} ₹{{vouchersDetailsobj.voucherAmt}} </p> -->
                                                            <p class="PreCouponDesc one-line-text"> Expire on: {{vouchersDetailsobj.expiryDate}}</p>
                                                            <a class="viewRestBtnAnch" href="#">
                                                                <button class="btn_1 viewRestBtn view_details">View Vouchers</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- /template 1 -->

                                            <div class="preBuyTemplate templateType2" v-if="vouchersDetailsobj.templateType == 2">
                                                <div class="preBuyImage" :style="{ backgroundImage: 'url(' + vouchersDetailsobj.templateImage + ')' }">
                                                    <div class="Voucheropacity-mask">
                                                        <div class="voucherDetails">
                                                            <!-- <span class="voucherStatus" :style="{color : vouchersDetailsobj.voucherStatusColor}">{{vouchersDetailsobj.voucherStatus}}</span> -->
                                                            <h5 class="preBuyCouponTitle">{{vouchersDetailsobj.voucherTitle}}</h5>
                                                            <p class="PreCouponDesc two-line-text">{{vouchersDetailsobj.shortDesc}}</p>
                                                            <!-- <p class="PreCouponDesc one-line-text">{{vouchersDetailsobj.dishTitle != '' ? vouchersDetailsobj.dishTitle+ ' |' : ''}} ₹{{vouchersDetailsobj.voucherAmt}} </p> -->
                                                            <p class="PreCouponDesc one-line-text"> Expire on: {{vouchersDetailsobj.expiryDate}}</p>
                                                            <a class="viewRestBtnAnch" href="#">
                                                                <button class="btn_1 viewRestBtn view_details">View Vouchers</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- /template 2 -->

                                            <div class="preBuyTemplate templateType3" v-if="vouchersDetailsobj.templateType == 3">
                                                <div class="preBuyImage" :style="{ backgroundImage: 'url(' + vouchersDetailsobj.templateImage + ')' }">
                                                    <div class="Voucheropacity-mask">
                                                        <div class="voucherDetails">
                                                            <!-- <span class="voucherStatus" :style="{color : vouchersDetailsobj.voucherStatusColor}">{{vouchersDetailsobj.voucherStatus}}</span> -->
                                                            <h5 class="preBuyCouponTitle">{{vouchersDetailsobj.voucherTitle}}</h5>
                                                            <p class="PreCouponDesc two-line-text">{{vouchersDetailsobj.shortDesc}}</p>
                                                            <!-- <p class="PreCouponDesc one-line-text">{{vouchersDetailsobj.dishTitle != '' ? vouchersDetailsobj.dishTitle+ ' |' : ''}} ₹{{vouchersDetailsobj.voucherAmt}} </p> -->
                                                            <p class="PreCouponDesc one-line-text"> Expire on: {{vouchersDetailsobj.expiryDate}}</p>
                                                            <a class="viewRestBtnAnch" href="#">
                                                                <button class="btn_1 viewRestBtn view_details">View Restaurant</button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- /template 3 -->
                                        </div>
                                    </div>
                                    
                                </section>

                            </div>
                        </div>
                    </div>
                </b-card-text>
              </b-tab>
              <!-- tab end -->

              <b-tab title="Wallet" @click="pushPofile('wallet')" style="padding:16px 10px;" :active="profileType == 'wallet'" title-item-class="mb-2 pills-li">
                <b-card-text>
                     <div class="section-services" style="margin-top:0px">
                         <div class="walletBanner">
                             <div class="row">
                                 <div class="col-6">
                                     <div class="walletInfoDiv">
                                        <img src="../../templateAssests/img/ckgallery/wallet-ck.png" width="150" height="130" style="object-fit:contain;">
                                        <h1 class="walletBalance">{{ parseFloat(walletBalance).toFixed(2) }} Points</h1>
                                        <p class="walletBalanceText">Wallet Balance</p>
                                     </div>
                                 </div>
                                 <!-- /Col -->

                                 <div class="col-6">
                                     <div class="fireWork noMobile">
                                        <img src="../../templateAssests/img/ckgallery/firework.png" width="150" height="150" style="object-fit:contain;">
                                     </div>
                                 </div>
                                 <!-- /Col -->
                             </div>
                        </div>
                        <!-- /banner -->
                        <section class="transactionDetailsSection">
                                 <div class="row">
                                     <div class="col-6">
                                         <h3 class="transactionHead">Transactions</h3>
                                     </div>
                                     <div class="col-6 align-right" style="width:50%!important;">
                                         <vue-picker placeholder="All" v-model="transactionType" @input="getUserWalletDetails" style="max-width:170px!important;margin-left:auto;">
											<vue-picker-option value="0">All</vue-picker-option>
											<vue-picker-option value="1">Credit</vue-picker-option>
											<vue-picker-option value="2">Debit</vue-picker-option>
										</vue-picker>
                                     </div>
                                     <!-- / col  -->
                                 </div>
                             <!-- /row -->
                             <table id="userWalletTable">
                                <tr v-if="walletIndex <= walletTableData.length" v-for="walletIndex in transactionsToShow" :key="walletIndex">
                                    <td class="transactionTypeImg">
                                        <img v-if="walletTableData[walletIndex-1].transactionType == 1" src="../../templateAssests/img/ckgallery/up-arrow.png" height="40" width="40">
                                        <img v-if="walletTableData[walletIndex-1].transactionType == 2" src="../../templateAssests/img/ckgallery/down-arrow.png" height="40" width="40">
                                    </td>
                                    <td>
                                        <p class="pointsDetails" v-if="walletTableData[walletIndex-1].transactionType == 1">{{walletTableData[walletIndex-1].addedBy}} added {{walletTableData[walletIndex-1].amount}} points to your wallet</p>
                                        <p class="pointsDetails" v-else-if="walletTableData[walletIndex-1].transactionType == 2 && walletTableData[walletIndex-1].event =='ORDER_PLACED'">{{walletTableData[walletIndex-1].amount}} points redeemed on order <a :href="'/orderDetails/viewDetails?id='+walletTableData[walletIndex-1].orderID+'?od=1'" class="customAnch">#{{walletTableData[walletIndex-1].orderID}}</a></p>
                                        <p class="pointsDetails" v-else-if="walletTableData[walletIndex-1].transactionType == 2 && walletTableData[walletIndex-1].event =='EXPIRED'">{{walletTableData[walletIndex-1].amount}} points expired</p>
                                        <span class="addedAt" v-if="walletTableData[walletIndex-1].transactionType == 2 && walletTableData[walletIndex-1].event =='EXPIRED'">{{walletTableData[walletIndex-1].expiryDate}}</span>
                                        <span class="addedAt" v-else>{{walletTableData[walletIndex-1].created_at}}</span>
                                    </td>
                                    <td class="align-right">
                                        <p class="pointsTransActions pointsCredit" v-if="walletTableData[walletIndex-1].transactionType == 1">+{{walletTableData[walletIndex-1].amount}} Pts</p>
                                        <p class="pointsTransActions pointsDebit" v-else-if="walletTableData[walletIndex-1].transactionType == 2">-{{walletTableData[walletIndex-1].amount}} Pts</p>
                                        <span class="expirySpan" v-if="walletTableData[walletIndex-1].transactionType == 1">Expiry: {{walletTableData[walletIndex-1].expiryDate}}</span>
                                    </td>
                                </tr>
                                </table>

                                <div class="align-center" v-if="transactionsToShow < walletTableData.length || walletTableData.length > transactionsToShow">
                                    <button @click="transactionsToShow += 5" class="btn_1 mb_5" style="background:#FFC60C;color:#000!important;padding:12px 20px;font-size:14px;">Load More</button>
                                </div>
                             
                             </section>
                             <!-- / section -->
                    </div>
                </b-card-text>
              </b-tab>
              <!-- tab end -->

              <b-tab title="My Address" @click="pushPofile('my-address')" :active="profileType == 'my-address'" title-item-class="mb-2 pills-li">
                <b-card-text>
                    <div class="section-services container">
                        <div class="reviews">
                            <div class="review_card">

                                 <div class="row">
					                <div class="col-md-6 review_content">
					                    <div class="clearfix">
					                        <h3 class="cardTitle">Address</h3>
					                    </div>
					                </div>

                                    <div class="col-md-6 review_content align-right">
					                    <div class="clearfix">
					                       <button class="btn btn-danger btnNewAdd" @click="addressModal">+ Add New Address</button>
					                    </div>
					                </div>
                                    
					            </div>
					            <!-- /row -->

                                <div class="row reply"  v-if="(userAddress != '' || userAddress.length != 0)">
					                <div class="col-md-6 otherAdd" v-for="addresses in userAddress" v-bind:key="addresses">
                                        <div class="deliveryCard align-left">
                                            <div class="addressType">
                                                <h5>{{addresses.deliveryAddressType}}</h5>
                                            </div>
                                            <!-- / address Type -->

                                            <div class="address">
                                                <p class="addressText">{{addresses.deliveryAddress}}</p>
                                            </div>
                                            <!--  /address -->
                                            <div class="row orderStatus_ViewOrder">
                                                <a class="col-6 customAnch" @click="removeAddress(addresses.userAddID)">Remove</a>
                                                
                                                <div class="col-6 align-right">
                                                     <a class="customAnch" @click="editAdress(addresses.userAddID)">Edit</a>
                                                </div>
                                        </div>
                                            <!-- <div class="deliverBtn align-right">
					                            <a class="customAnch" @click="editAdress(addresses.userAddID)">Edit</a>
                                            </div> -->

                                        </div>
                                        <!-- / delivery Card -->
					                </div>
                                    <!-- /col -->
					            </div>
					            <!-- /reply -->
                            </div>
                        </div>
                    </div>
                </b-card-text>
              </b-tab>
              <!-- tab end -->

              <b-tab title="Reviews" @click="pushPofile('my-review')" :active="profileType == 'my-review'" title-item-class="mb-2 pills-li">
                <b-card-text>
                    <div class="section-services container">
                        <div class="reviews">
                            <div class="">
                                <div class="row">
                                    <div class="col-md-6 review_content">
                                        <div class="clearfix">
                                            <p style="font-size: 24px;">Reviews</p>
                                        </div>
                                    </div>
                                </div>

                                <div v-for="allReviews in userReview" :key="allReviews.FeedbackId">
                                    <section class="reviewSection">
                                        <div class="row">
                                            <div  class="col-md-12">
                                                <div class="strip strip-review">
                                                    <div class="row menu_item">
                                                        <div class="row">
                                                            <div class="col-md-6 col-sm-12">
                                                                <div class="row">
                                                                    <div class="col-md-2 col-sm-6">
                                                                        <img :src=" allReviews.image == null || allReviews.image == '' ? 'img' : allReviews.image"
                                                                        onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';"
                                                                        data-src="img/menu-thumb-1.jpg"
                                                                        alt="thumb"
                                                                        class="reviewImage" />
                                                                    </div>
                                                                    <div class="col-md-10 col-sm-6">
                                                                        <p class="restName">{{ allReviews.restName }} - {{ allReviews.branchName }}</p>
                                                                        <p style="color: #777777; font-size: 14px;">Order #<span class="restOrderId">{{ allReviews.orderId }}</span></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-sm-12 text-right">
                                                                <p class="fdbDate">{{ allReviews.date }}</p>
                                                            </div>
                                                            <div class="col-12" v-if="allReviews.rating > 0">
                                                                <span v-for="index in 10" :key="index">
                                                                    <i
                                                                    v-if="index <= allReviews.rating"
                                                                    class="fa fa-star"
                                                                    style="margin-right: 5px; color: #ffc60c"
                                                                    ></i>
                                                                    <i
                                                                    v-else
                                                                    class="fa fa-star"
                                                                    style="margin-right: 5px; color: #dadada"
                                                                    ></i>
                                                                </span>
                                                            </div>
                                                            <p class="starRating">{{ allReviews.rating }} of 10</p>
                                                        </div>
                                                        <div class="row">
                                                            <p class="userReviewText">{{ allReviews.review }}</p>
                                                        </div>
                                                        <div class="row" v-if="allReviews.allReplies != ''">
                                                            <hr style="margin-top: 12px; margin-bottom: 12px;">
                                                            <div class="replyBox" v-for="allReplies in allReviews.allReplies" :key="allReplies.FeedbackId">
                                                                <div class="row">
                                                                    <div class="col-6 text-left">
                                                                        <p style="color: #979797; font-size: 11px; line-height: 13px;">Reply from,</p>
                                                                        <p class="adminReply" v-if="allReviews.restName != null"> {{ allReviews.restName }} - {{ allReviews.branchName }}</p>
                                                                    </div>
                                                                    <div class="col-6 text-right">
                                                                        <p class="fdbDate">{{ allReplies.repliedDate }}</p>
                                                                    </div>
                                                                </div>
                                                                <p class="userReviewText">{{ allReplies.replyText }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>

                                <!-- <table class="col-12">
                                    <tr v-if="reviewIndex <= userReview.length" v-for="reviewIndex in reviewsToShow" :key="reviewIndex">
                                        <td>
                                            <section class="reviewSection">
                                                <div class="row">
                                                    <div  class="col-md-12">
                                                        <div class="strip strip-review">
                                                            <div class="row menu_item">
                                                                <div class="row">
                                                                    <div class="col-md-6 col-sm-12">
                                                                        <div class="row">
                                                                            <div class="col-md-2 col-sm-6">
                                                                                <img
                                                                                    :src="
                                                                                    userReview[reviewIndex - 1].image == null ||
                                                                                    userReview[reviewIndex - 1].image == ''
                                                                                        ? 'img'
                                                                                        : userReview[reviewIndex - 1].image
                                                                                    "
                                                                                    onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';"
                                                                                    alt="thumb"
                                                                                    class="reviewImage"
                                                                                />
                                                                            </div>
                                                                            <div class="col-md-10 col-sm-6">
                                                                                <p class="restName">{{ userReview[reviewIndex - 1].restName }} - {{ userReview[reviewIndex - 1].branchName }}</p>
                                                                                <p style="color: #777777; font-size: 14px;">Order #<span class="restOrderId">{{ userReview[reviewIndex - 1].orderId }}</span></p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 text-right">
                                                                        <p class="fdbDate">{{ userReview[reviewIndex - 1].date }}</p>
                                                                    </div>
                                                                    <div class="col-12" v-if="userReview[reviewIndex - 1].rating > 0">
                                                                        <span v-for="index in 10" :key="index">
                                                                            <i
                                                                            v-if="index <= userReview[reviewIndex - 1].rating"
                                                                            class="fa fa-star"
                                                                            style="margin-right: 5px; color: #ffc60c"
                                                                            ></i>
                                                                            <i
                                                                            v-else
                                                                            class="fa fa-star"
                                                                            style="margin-right: 5px; color: #dadada"
                                                                            ></i>
                                                                        </span>
                                                                    </div>
                                                                    <p class="starRating">{{ userReview[reviewIndex - 1].rating }} of 10</p>
                                                                </div>
                                                                <div class="row">
                                                                    <p class="userReviewText">{{ userReview[reviewIndex - 1].review }}</p>
                                                                </div>
                                                                <div class="row" v-if="userReview[reviewIndex - 1].allReplies != ''">
                                                                    <hr style="margin-top: 12px; margin-bottom: 12px;">
                                                                    <div 
                                                                    class="replyBox"
                                                                    v-for="allReplies in userReview[reviewIndex - 1].allReplies"
                                                                    :key="allReplies"
                                                                    >
                                                                        <div class="row">
                                                                            <div class="col-6 text-left">
                                                                                <p style="color: #979797; font-size: 11px; line-height: 13px;">Reply from,</p>
                                                                                <p class="adminReply" v-if="userReview[reviewIndex - 1].restName != null"> {{ userReview[reviewIndex - 1].restName }} - {{ userReview[reviewIndex - 1].branchName }}</p>
                                                                            </div>
                                                                            <div class="col-6 text-right">
                                                                                <p class="fdbDate">{{ allReplies.repliedDate }}</p>
                                                                            </div>
                                                                        </div>
                                                                        <p class="userReviewText">{{ allReplies.replyText }}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </td>
                                    </tr>
                                </table>

                                <div class="align-center" v-if="reviewsToShow < userReview.length || userReview.length > reviewsToShow">
                                    <button @click="reviewsToShow += 5" class="btn_1 mb_5" style="background: #ffc60c; color: #000 !important; padding: 12px 20px; font-size: 14px;">
                                        Load More
                                    </button>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </b-card-text>
            </b-tab>
            <!-- tab end -->

              <b-tab title="Profile" @click="pushPofile('my-profile')" :active="profileType == 'my-profile'" title-item-class="mb-2 pills-li">
                <b-card-text>
                    <div class="section-services container">
                        <!-- <div class="align-right">
                            <button class="btn btn-danger btnNewAdd">Edit</button>
                        </div> -->
                        
                        <table border="1" class="custTable">
                                        <tbody>
                                        <tr>
                                            <td>
                                                <p>Name</p>
                                            </td>
                                            <td>
                                                <p>{{userName}}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Email</p>
                                            </td>
                                            <td>
                                                <p>{{emailID}}</p>
                                            </td>
                                        </tr>

                                        <tr v-if="mobileNo != ''">
                                            <td>
                                                <p>Mobile Number</p>
                                            </td>
                                            <td>
                                                <p>{{mobileNo}}</p>
                                            </td>
                                        </tr>

                                        <tr v-if="isCorporateUser == 1">
                                            <td>
                                                <p>Corporate Code</p>
                                            </td>
                                            <td>
                                                <p>{{corporateCode}}</p>
                                            </td>
                                        </tr>

                                        <tr v-if="isCorporateUser == 1">
                                            <td>
                                                <p>Corporate Name</p>
                                            </td>
                                            <td>
                                                <p>{{corporateName}}</p>
                                            </td>
                                        </tr>
                                        
                                    </tbody>
                                    </table>
                    </div>
                </b-card-text>
              </b-tab>
              <!-- tab end -->

            </b-tabs>
        </div>
    </main>

    <b-modal id="modal-allVoucher" scrollable  modal-class="allVoucherModal" hide-header  hide-footer size="lg" centered>
      <div class="closeModalDiv"><a @click="$bvModal.hide('modal-allVoucher')"><img class="mainLogoImg" src="../../templateAssests/img/ckgallery/cancelBtn.png"  width="17" height="17" alt=""></a></div>
        <div class="container voucherDiv">
            <div class="row" v-if="allVouchersDetails.length >= 0">
                <div class="preBuyCard col-6" v-on:click="redirectToRestaurant(vouchersDetailsobj.restIdentifierString)" v-for="vouchersDetailsobj in allVouchersDetails" v-bind:key="vouchersDetailsobj">
                    <div class="preBuyTemplate templateType1" v-if="vouchersDetailsobj.templateType == 1">
                        <div class="preBuyImage" :style="{background : vouchersDetailsobj.templateColor}">
                            <div class="Voucheropacity-mask">
                                <div class="voucherDetails">
                                    <span class="voucherStatus" :style="{color : vouchersDetailsobj.voucherStatusColor}">{{vouchersDetailsobj.voucherStatus}}</span>
                                    <img class="preBuyImageTempalet1" :src="vouchersDetailsobj.templateImage == null || vouchersDetailsobj.templateImage == ''? 'img' : vouchersDetailsobj.templateImage">
                                    <h5 class="preBuyCouponTitle">#{{vouchersDetailsobj.voucherIdentifier}}</h5>
                                    <p class="PreCouponDesc one-line-text">{{vouchersDetailsobj.restaurantName}}</p>
                                    <p class="PreCouponDesc preCouponTitleModal one-line-text">{{vouchersDetailsobj.dishTitle != '' ? vouchersDetailsobj.dishTitle+ ' |' : ''}} ₹{{vouchersDetailsobj.voucherAmt}} </p>
                                    <p class="PreCouponDesc one-line-text"> Expire on: {{vouchersDetailsobj.expiryDate}}</p>
                                    <a class="viewRestBtnAnch" :href="'/restaurantDetails/'+vouchersDetailsobj.restIdentifierString">
                                        <button class="btn_1 viewRestBtn view_details">View Restaurant</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <!-- /template 1 -->

                    <div class="preBuyTemplate templateType2" v-if="vouchersDetailsobj.templateType == 2">
                        <div class="preBuyImage" :style="{ backgroundImage: 'url(' + vouchersDetailsobj.templateImage + ')' }">
                            <div class="Voucheropacity-mask">
                                <div class="voucherDetails">
                                    <span class="voucherStatus" :style="{color : vouchersDetailsobj.voucherStatusColor}">{{vouchersDetailsobj.voucherStatus}}</span>
                                    <h5 class="preBuyCouponTitle">#{{vouchersDetailsobj.voucherIdentifier}}</h5>
                                    <p class="PreCouponDesc one-line-text">{{vouchersDetailsobj.restaurantName}}</p>
                                    <p class="PreCouponDesc preCouponTitleModal one-line-text">{{vouchersDetailsobj.dishTitle != '' ? vouchersDetailsobj.dishTitle+ ' |' : ''}} ₹{{vouchersDetailsobj.voucherAmt}} </p>
                                    <p class="PreCouponDesc one-line-text"> Expire on: {{vouchersDetailsobj.expiryDate}}</p>
                                    <a class="viewRestBtnAnch" :href="'/restaurantDetails/'+vouchersDetailsobj.restIdentifierString">
                                        <button class="btn_1 viewRestBtn view_details">View Restaurant</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- /template 2 -->

                    <div class="preBuyTemplate templateType3" v-if="vouchersDetailsobj.templateType == 3">
                        <div class="preBuyImage" :style="{ backgroundImage: 'url(' + vouchersDetailsobj.templateImage + ')' }">
                            <div class="Voucheropacity-mask">
                                <div class="voucherDetails">
                                    <span class="voucherStatus" :style="{color : vouchersDetailsobj.voucherStatusColor}">{{vouchersDetailsobj.voucherStatus}}</span>
                                    <h5 class="preBuyCouponTitle">#{{vouchersDetailsobj.voucherIdentifier}}</h5>
                                    <p class="PreCouponDesc one-line-text">{{vouchersDetailsobj.restaurantName}}</p>
                                    <p class="PreCouponDesc preCouponTitleModal one-line-text">{{vouchersDetailsobj.dishTitle != '' ? vouchersDetailsobj.dishTitle+ ' |' : ''}} ₹{{vouchersDetailsobj.voucherAmt}} </p>
                                    <p class="PreCouponDesc one-line-text"> Expire on: {{vouchersDetailsobj.expiryDate}}</p>
                                    <a class="viewRestBtnAnch" :href="'/restaurantDetails/'+vouchersDetailsobj.restIdentifierString">
                                        <button class="btn_1 viewRestBtn view_details">View Restaurant</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /template 3 -->
                </div>
            </div>
        </div>
    </b-modal>

    <footerLayout />
	<!-- footer layout -->
    </div>
</template>
<style scoped src="../../templateAssests/css/listing.css"></style>
    <style scoped src="../../templateAssests/css/detail-page.css"></style>
<style>

.tabs{
    width: 100%;
    background: #ffffff;
}
.setionTitle {
    font-size: 30px;
    margin: 0 0 50px 0;
    color: #222222;
}
.nav-pills{
    margin-top: 40px!important;

}
.tab-content{
    /* margin: 30px 0px!important; */
    border-left: 1px solid #f1f1f1

}
.card-text{
    color: #222222;
}
.policyHead
{
    margin: 35px 0;
    margin-bottom: 10px;
    color: #A4827D;
    font-size: 24px;
}
ul, li{
    text-decoration: none;
    list-style: none;
    padding-left: 0px;
}
li h4{
    color: #A4827D;
    margin-top: 20px;
    margin-bottom: 5px;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link{
        color: #000;
    background-color: #ffc60c;
}
.section-services
{
    margin-top: 30px;
}
.section-services p{
    margin-bottom: 0px;
    line-height: 30px;
    font-size: 14px;
}
.section-services a{
    color: #F3766A;
}
.custTable {
    border: 1px solid #f1f1f1;
    margin-top: 30px;
    margin-bottom: 30px;
}
.custTable tr th {
    background-color: #f1f1f1;
    text-align: left;
}
table, tr, td, th {
    border-spacing: 0;
    padding: 0;
    margin: 0;
    vertical-align: top;
}
tr:nth-child(even) {
  background-color: #f8f8f8;
}
.custTable tr th, .custTable tr td {
    padding: 15px;
    border: 1px solid #f1f1f1;
}
.process-table{
    width: 100%;
}
.mainDiv
{
    padding: 50px;
}
@media (max-width:971px)
{
	.mainDiv
	{
		padding: 0px!important;
	}
    .walletInfoDiv{
        top: 0px!important;
    }
}
.review_card{
            padding:25px 25px 25px 25px;
        }
        .deliveryCard{
            border:1px solid #ededed;
            border-radius:3px;
            padding: 20px;
        }
        .addressType h5{
            font-size:16px;
        }
        .address span{
            font-size:14px;
        }
        .deliverBtn{
            margin-top:17px;
        }
        .btn_1{
            background:#A4827D;
        }
        .disabledBtn{
            background:rgba(52, 58, 64, 0.25) !important;
			cursor: not-allowed;
			color: #212121!important;
			
        }
        .btnNewAdd{
            background: #F3766A;
        }
        .btnSave{
                width: 85px;
                height: 35px;
        }
        .otherAdd{
            margin-top:20px;
		}
        .comingSoonCard
        {
            border: 1px solid #f0f0f0;
        }
        .comingSoonText
        {
            margin: 75px;
        }
        .menu_item .orderTotalSum
        {
            color: #222!important;
        }
        .placedOn
        {
            font-size: 12px;
            color: #777777;
        }
        .orderStatus_ViewOrder
        {
            margin-top: 10px;
        }
        .view_details
        {
            padding: 8px 15px!important;
            width: fit-content;
        }
        .customAnch
        {
            cursor: pointer;
        }
        .ordersSection
        {
            padding-top: 15px;
        }
        .addressText
        {
            line-height: 22px;
            color: #777777;
        }
        .pills-li a
        {
            padding: 1rem 1rem;
        }
        .tabs
        {
            min-height: 500px;
        }
        .vue-picker{
			display: block;
			width: 150px;
			font-size: 0.9rem;
			font-weight: 400;
			line-height: 1.5;
			color: #495057;
			background-color: #fbfbfb;
			border-radius: 5px;
		}
		.vue-picker__dropdown{
			border:1px solid #fff!important;
		}
		.vue-picker__opener:focus{
			box-shadow: none!important;
			border:transparent!important;
		}
    .preBuyCard
	{
		width: fit-content;
		margin-bottom: 20px;
	}
	.preBuyTemplate
	{
		cursor: pointer;
		min-width: 310px;
	}
    .viewRestBtn
    {
        background: #FFC60C;
    }
	.preBuyImage
	{
		object-fit:cover;
		min-width: 320px;
        /* min-height: 160px; */
        min-height: 190px;
		border-radius:8px;
        background-position: 50%;
        background-repeat: no-repeat;
        /* border-radius: 5px; */
        overflow: hidden;
        background-size: cover;
	}
    .Voucheropacity-mask
    {
        width: 94%;
        height: 100%;
        position: absolute;
        z-index: 2;
		border-radius:8px;
        background-color: rgba(0,0,0,.2);
    }
    .templateType3 .preBuyImage .Voucheropacity-mask
    {
        background-color: rgba(0,0,0,.4)!important;
    }
    .voucherDetails
    {
        padding: 20px 16px;
        height: 100%;
    }
	.preBuyCouponTitle
	{
		font-family: 'GothamMedium';
		color: #fff;
		font-size: 17px;
	}
	.PreCouponDesc
	{
		color: #fff;
		font-size: 14px;
        /* display: -webkit-box; */
	}
	.preBuyImageTempalet1
	{
		height: 65px;
		object-fit: contain;
		position: absolute;
		right: 20px;
		bottom: 22px;
        /* float: right;
        position: relative;
        top: 65px;
        right: 0; */
	}
    .preBuyImage, .inImage
    {
        max-width: 320px!important;
        max-height: 190px!important;
    }
    .voucherStatus
    {
        position: absolute;
        right: 22px;
    }
    .viewRestBtnAnch
{
	position: absolute;
	bottom: 18px;
}
#modal-allVoucher___BV_modal_body_::-webkit-scrollbar
{
    width: 3px!important;
    background-color: #F5F5F5!important;
}

#modal-allVoucher___BV_modal_body_::-webkit-scrollbar-thumb
{
    border-radius: 10px!important;
    background-color: #e0e0e0!important;
    border: 2px solid #e0e0e0!important;
}
.closeModalDiv{
        float: right;
        padding-top: 6px;
        padding-right: 9px;
        cursor: pointer;
    }
    .voucherDiv
    {
        margin-top: 40px;
    }
@media (min-width: 575px){
    .tabsPostion{
        /* border-right: 1px solid #f1f1f1; */
        position: sticky;
        top: 90px;
        height: 100%;
        padding-left: 20px;
    }
}
</style>
<style scoped>
a.menu_item
{
    box-shadow: 0px 0px 24px 0px rgb(46 51 51 / 5%);
}
a.btn_1:hover, .btn_1:hover
{
    background: #F3766A;
}
.walletBanner
{
    /* height: 276px; */
    height: 262px;
    background: #FFC60C;
}
.walletInfoDiv
{
    position: absolute;
    top: 10px;
    /* top: 28px; */
    left: 28px;
}
.fireWork
{
    position: absolute;
    /* top: 71px; */
    top: 50px;
    right: 103px;
}
.walletBalance
{
    font-family: 'GothamMedium';
    font-size: 32px;
    color: #000;
    margin-top: 18px;
}
.walletBalanceText
{
    font-size: 22px;
    color: #212121;
    /* margin-top: 8px; */
}
.transactionDetailsSection
{
    padding: 24px 18px 14px 18px;
}
.transactionHead
{
    font-size: 18px;
    color: #000;
}
#userWalletTable
{
      width: 100%;
      margin-top: 20px;
}
#userWalletTable td, th {
  padding: 8px;
}

#userWalletTable tr:nth-child(even) {
  background-color: #fff;
}
#userWalletTable tr:nth-child(odd) {
  background-color: #fbfbfb;
}
.transactionTypeImg
{
    width: 50px;
}
.pointsDetails
{
    font-size: 14px;
    color: #242424;
}
.addedAt, .expirySpan
{
    font-size: 13px;
    color: #979797;
    padding-right: 12px;
}
.pointsTransActions
{
    font-size: 15px;
    padding-right: 12px;
}
.pointsCredit
{
    color: #9FC925;
}
.pointsDebit
{
    color: #F3766A;
}
.preCouponTitleModal
{
    margin-bottom: 4px;
}
.CateresScore strong {
  padding: 0 0 0 0;
  margin-right: 5px;
}
.score {
  /* float:right!important; */
  font-size: 13px;
  color: #212121;
  margin-bottom: 8px;
}
.score strong:before {
  color: #ffc60c !important;
}
.rating_count {
  color: #626262;
  font-size: 13px;
}
.reviews {
  margin-bottom: 50px;
}
.reviewSection {
  padding-top: 10px;
}
.menu_item {
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: block;
  position: relative;
  padding: 10px;
  margin-bottom: 10px;
}
.strip-review {
  margin-bottom: 10px;
}
.reviewImage {
  width: 70px;
  height: 60px;
}
.restName {
	color: #663333;
	font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.restOrderId {
  color: #663333;
  font-size: 14px;
  font-weight: bold;
}
.fdbDate {
	padding-right: 0;
  color: #979797;
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 8px;
}
.starRating {
	font-size: 10px;
  color: #663333;
  margin-bottom: 8px;
  line-height: 10px;
}
.userReviewText {
  color: #663333;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  word-break: break-word;
}
.adminReply {
  color: rgb(102, 51, 51);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}
.replyBox {
  border-left: 5px solid #F3766A;
}
</style>